import imagesLoaded from 'imagesloaded';

class InitImagesLoaded {
    constructor(els) {
        imagesLoaded(
            els, 
            {background: true}, 
            () => document.body.classList.remove('loading')
        );
    }
};

export default InitImagesLoaded;
