import H from '@dogstudio/highway';
import { TweenMax, Circ } from 'gsap';

class Fade extends H.Transition {
    in({ from, to, done }) {
        // Reset Scroll
        window.scrollTo(0, 0);
    
        // Remove Old View
        from.remove();
    
        // Animation
        TweenMax.fromTo(to, .5,
            {
                opacity: 0 ,
            },
            {
                opacity: 1,
                clearProps:"all",
                ease: Circ.easeIn,
                onComplete: done
            }
        );
      }
    
      out({ from, done }) {
        // Animation
        TweenMax.fromTo(from, .5,
            { 
                opacity: 1
            },
            {
                opacity: 0,
                ease: Circ.easeOut,
                clearProps:"all",
                onComplete: done
            }
        );
      }
}

export default Fade;