class InitGallerySlide {
    constructor(slide, data, lightbox) {
        this.lightbox = lightbox;
        this.overlayContainer = slide;
        this.elLeft = "";
        this.elRight = "";

        this.createOverlay();
        this.addClickEvent();
    }

    createOverlay() {
        this.overlayContainer.insertAdjacentHTML('beforeend', '<div class="custom-arrow custom-arrow-left"></div><div class="custom-arrow custom-arrow-right"></div>');
    };

    addClickEvent() {
        this.elLeft = this.overlayContainer.querySelector(".custom-arrow-left");
        this.elRight = this.overlayContainer.querySelector(".custom-arrow-right");

        this.elLeft.addEventListener('click', () => {    
            this.lightbox.prevSlide();
        });

        this.elRight.addEventListener('click', () => {
            this.lightbox.nextSlide();
        });
    }
};

export default InitGallerySlide;