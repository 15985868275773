class InitNavigation {
    constructor(el) {
        this.body = document.querySelector("body");
        this.burger = el.querySelector(".burger");
        this.nav = el;
        this.linkList = el.querySelectorAll(".mainnav > li");
        this.links = this.body.querySelectorAll(".mainnav > li > a, header > a");
        this.logoLink = el.querySelector("header > a");
        this.subnavs = el.querySelectorAll(".subnav");
        this.subnavArr = el.querySelectorAll(".subnav a");

        this.initBurger();
        this.initNavigationChange();
        this.initSubnavChange(this.subnavArr);
    };

    initBurger() {
        this.burger.addEventListener("click", () => {
            this.burger.classList.toggle("burger-active");
            this.nav.classList.toggle("nav-active");
            this.body.classList.toggle("no-scroll");

            for (let i = 0; i < this.subnavs.length; i++) {
                const subnav = this.subnavs[i];

                this.initMobileSubNav(subnav);
            }
        });
    };

    initMobileSubNav(subnav) {
        let links = subnav.querySelectorAll("a");

        for (let i = 0; i < links.length; i++) {
            const link = links[i];

            link.addEventListener("click", () => {
                this.burger.classList.remove("burger-active");
                this.nav.classList.remove("nav-active");
                this.body.classList.remove("no-scroll"); 
            });
        };
    };

    initNavigationChange() {
        // Check Active Link
        for (let i = 0; i < this.links.length; i++) {
            const link = this.links[i];
            const linkListItem = link.parentNode;
            // Clean class
            linkListItem.classList.remove("active");

            // Active link
            if ((link.href === location.href) || ((location.href.includes(`${link.href}`)) && link.pathname != "/"  )) {
                linkListItem.classList.add("active");
                
                this.closeBurgerOverlay();

                // if (!link.nextElementSibling) {
                //     this.closeBurgerOverlay();
                // }

                // if((link.nextElementSibling) && (!linkListItem.classList.contains("active"))) {
                
                // }

                // remove subnav
                if (!location.hash) {
                    this.removeActiveFromSubNavLinks(this.subnavArr)
                }
            };
        };
    };

    closeBurgerOverlay() {
        this.burger.classList.remove("burger-active");
        this.nav.classList.remove("nav-active");
        this.body.classList.remove("no-scroll");
    };

    initSubnavChange(subnavArray) {
        for (let i = 0; i < subnavArray.length; i++) {
            let subNavLink = subnavArray[i];
            
            subNavLink.addEventListener("click", () => {
                this.removeActiveFromSubNavLinks(subnavArray);
                subNavLink.classList.add("sub-anchor");
            });
        };
    };

    removeActiveFromSubNavLinks(subnavArray) {
        for (let i = 0; i < subnavArray.length; i++) {
            subnavArray[i].classList.remove("sub-anchor");
        }
    };
};

export default InitNavigation;
