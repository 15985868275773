// this is needed to trigger the parcel pipeline to transform scss to css and bundle it
import "../scss/app.scss";

// JS imports //
import Highway from "@dogstudio/highway";
import GLightbox from "glightbox";
import Rellax from "rellax";

// GLOBAL
import InitImagesLoaded from "./global/imagesloaded";

// MODULES
import Fade from "../modules/pagetransitions/pagetransitions"
import InitNavigation from "../modules/navigation/navigation";
import InitGallerySlide from "../modules/gallery/gallery"

// START // initializing modules on first page view
const Navigation = new InitNavigation(document.querySelector("nav"));

if (document.querySelector(".rellax")) {
    const rellax = new Rellax('.rellax');
}

// on init
new InitImagesLoaded(document.querySelectorAll("img"));

if (document.querySelector(".glightbox")) {
    const lightbox = GLightbox({
        svg: {
            close: '<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 58 (84663) - https://sketch.com --> <title>Icon / X</title> <desc>Created with Sketch.</desc> <g id="Projekte-/-Projekt-X" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"> <g id="Desktop--Copy" transform="translate(-1202.000000, -38.000000)" stroke="#000000" stroke-width="3"> <g id="Icon-/-X" transform="translate(1204.000000, 40.000000)"> <path d="M-4,13 L30,13" id="Line-/" transform="translate(13.000000, 13.000000) rotate(-45.000000) translate(-13.000000, -13.000000) "></path> <path d="M-4,12.5 L30,12.5" id="Line-\" transform="translate(13.000000, 12.500000) rotate(45.000000) translate(-13.000000, -12.500000) "></path> </g> </g> </g> </svg>'
        },    
        closeOnOutsideClick: false,
        beforeSlideLoad: (slide, data) => {
            new InitGallerySlide(slide, data, lightbox);
        }
    });
}

// END // initializing modules on first page view

// initializing page transitions
const H = new Highway.Core({
    transitions: {
        default: Fade
    }
});

// START // initializing modules on page changes 
H.on("NAVIGATE_IN", ({ to, location }) => {

    Navigation.initNavigationChange();
});

H.on("NAVIGATE_END", ({ to, location }) => {

    new InitImagesLoaded(document.querySelectorAll("img"));

    if (document.querySelector(".glightbox")) {
        const lightbox = GLightbox({
            svg: {
                close: '<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 58 (84663) - https://sketch.com --> <title>Icon / X</title> <desc>Created with Sketch.</desc> <g id="Projekte-/-Projekt-X" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"> <g id="Desktop--Copy" transform="translate(-1202.000000, -38.000000)" stroke="#000000" stroke-width="3"> <g id="Icon-/-X" transform="translate(1204.000000, 40.000000)"> <path d="M-4,13 L30,13" id="Line-/" transform="translate(13.000000, 13.000000) rotate(-45.000000) translate(-13.000000, -13.000000) "></path> <path d="M-4,12.5 L30,12.5" id="Line-\" transform="translate(13.000000, 12.500000) rotate(45.000000) translate(-13.000000, -12.500000) "></path> </g> </g> </g> </svg>'
            },    
            closeOnOutsideClick: false,
            beforeSlideLoad: (slide, data) => {
                new InitGallerySlide(slide, data, lightbox);
            }
        });
    }

    if (document.querySelector(".rellax")) {
        const rellax = new Rellax('.rellax');
    }

    // on navigation to new site with hash scroll to #hash if there is one
    if (location.anchor) {
        // Get element
        const el = document.querySelector(location.anchor);

        if (el) {
        // Scroll to element
        window.scrollTo(el.offsetLeft, el.offsetTop);
        }
    }
});
// END // initializing modules on page changes 